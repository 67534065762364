.containerLoading {
  filter: blur(3px);
}

.listContainer {
    // min-height: calc(100vh - 310px);
    // margin-top: -115px;
  
    // min-height: calc(100vh - 310px);
    padding-bottom: 20px;
    margin: 0 auto;
    @media screen and (min-width: 1024px) and (max-width: 1440px) {
      margin: 10px auto 0 auto;
    }
    max-width: 1020px;
  }
  
  .headingContainer {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #00454a;

    @media screen and (max-width: 1024px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  
  .heading {
    font-size: 1.5rem;
    margin-bottom: 0.5em;
    line-height: 1.4;
    color: #ffffff;
    text-align: center;
  
    @media screen and (max-width: 1024px) {
      padding: 0 10px;
      font-size: 1.1rem;
      margin-bottom: 2px;
    }
  
    .icon {
      display: inline-flex;
      border: 2px solid #fff;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      margin-right: 10px;
      min-width: 25px;
  
      svg {
        width: 18px;
        height: 18px;
        margin: auto;
      }
    }
  }
  
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &.table {
      background-color: #ffffff;
      flex-direction: column;
    }
  }
  
  .loader {
    margin: auto;
    width: 60px;
    height: 60px;
    background-color: #c19237;
    border-radius: 100%;
    animation: pulseScaleOut 1s infinite ease-in-out;
  }

  .offersHint {
    background-color: #F3EDDC;
    border: 2px solid #D4A976;
    border-radius: 5px;
    font-size: 1rem;
    padding: 10px;
  }

  .filtersContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: calc(1020px - 2rem);
    // padding: 1rem;
    justify-content: center;
    margin: auto;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  
    .filter {
      min-width: 300px;
      margin: 0 1rem 1rem;
      background-color: #ffffff;
      border-radius: 12px;
    }
  
    .filterSmaller {
      min-width: 200px;
      max-width: 300px;
      margin: 0 1rem 1rem;
      // background-color: #ffffff;
      border-radius: 12px;
    }
  
    .filtersSubmit {
      min-width: 200px;
    }
  }
  
  .filtersContainerMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    padding: 20px 10px;
  
    .filterSmaller,
    .filter {
      width: 100%;
      margin-top: 1rem;
    }
  
    .filtersSubmit {
      width: 75%;
      margin-top: 1rem;
      min-height: 75px;
    }
  
    .closeMobileFilters {
      position: absolute;
      right: 0;
      top: 0;
      align-self: flex-end;
    }
  
    .sortingDirectionIcon {
      position: absolute;
      right: 0;
    }
  }
  
  .mobileFiltersWrapper {
    @media screen and (min-width: 1024px) {
      display: none;
    }
    color: #ffffff;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  
    .filtersButton {
      height: 40px;
      font-size: 20px;
      display: flex;
      align-items: center;
      text-decoration: underline;
    }
  
    .sorting {
      display: flex;
    }
  }
  
  .loader {
    margin: auto;
    width: 60px;
    height: 60px;
    background-color: #c19237;
    border-radius: 100%;
    animation: pulseScaleOut 1s infinite ease-in-out;
  }
  
  
  .noResults {
    font-size: 2rem;
    color: #202020;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @keyframes pulseScaleOut {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }


.viewButton {
  background-color: #ffffff;
  color: #00454a;
  border-radius: 12px;
  padding: 10px 30px;
  text-align: center;
  font-size: 16px;

  z-index: 1;
  height: 50px;
  min-width: 162px;
  border-top-right-radius: 8px;

  @media screen and (max-width: 1024px) {
    padding: 8px;
    min-width: auto;
  }

  &.Mui-selected {
    background-color: #BB9269 !important;
    &:hover {
      background-color: #BB9269 !important;;
      color: #ffffff !important;;
    }
  }

  &:hover {
    background-color: #D4A976 !important;;
    color: #ffffff !important;;
  }
}

.regionFilters {
  .regionButton {
    background-color: #ffffff;
    color: #202020;
    border-color: #20202033;
    border-radius: 10px;
    padding: 8px 6px;
    text-align: center;
    font-size: 0.9rem;
    text-transform: initial;

    @media screen and (max-width: 1024px) {
      padding: 5px 8px;
      font-size: 1rem;
    }
  }
}

.globeBtn {
  position: absolute;
  top: 40%;
  left: 20px;
  z-index: 100;

  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 5px;
  }

  @media screen and (max-width: 1024px) {
    left: 10px;
  }
}

.filterDialogActions {
  width: 100%;
  bottom: 0px;
  background: white;
  padding: 1rem 0 0;
  display: flex;
  justify-content: space-between;
  button {
    min-width: 49%;
  }
}

.globeHeader {
  position: absolute;
  transform: translateY(-10px);
  z-index: 102;
  width: 100%;
  margin: 0 auto;
  opacity: 1;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.globeSpinHint {
  top: 50%;
  background: rgba(255, 255, 255, 0.3);
  padding: 70px 0;
  font-size: 2.4rem;
  margin-top: 23%;
  max-width: 70vw;
  text-shadow: #9b9b9b 0px 0px 5px;
  backdrop-filter: blur(5px);

  svg {
    transform: scale(2);
  }

  @media screen and (max-width: 1024px) {
    font-size: 1.8rem;
    line-height: 2rem;
    max-width: 100%;

    svg {
      transform: scale(1.3);
    }
  }
}
