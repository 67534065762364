.topSection {
  // height: 1200px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #0a0a0a;
  @media screen and (max-width: 1024px) {
    // height: 325px;
    img {
      object-fit: cover !important;
      max-width: 100%;
      // height: 58% !important;
    }
  }
  // @media screen and (max-width: 1300px) and (min-width: 1024px) {
  //   height: 60vh;
  // }

  &.topSectionPattern {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../public//images/night-sky-pattern.png');
    background-size: cover, 40%;
    background-repeat: no-repeat, repeat;
  }

  video {
    width: 100%;
    @media screen and (max-width: 1024px) {
      object-fit: cover;
      max-width: 100%;
      height: 100%;
    }
  }

  .topImage {
    width: 100%;
    @media screen and (max-width: 1024px) {
      object-fit: cover;
      max-width: 100%;
      height: 100%;
    }
  }

  img {
    width: 100%;
    @media screen and (max-width: 1024px) {
      object-fit: cover;
      max-width: 100%;
      height: 100%;
    }
  }

  .topBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // z-index: -1;
  }

  .topSectionText {
    text-align: center;
    position: relative;
    // margin: 120px auto 79px;
    margin: 75px auto 30px;
    font-weight: 400;
    font-size: 50px;
    line-height: 68px;
    color: white;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    @media screen and (max-width: 1024px) {

      transform: none;
      /* Mobile/H1 */

      font-style: normal;
      font-weight: 400;
      font-size: 17px !important;
      line-height: 43px;
      margin: 0;
      /* or 123% */

      text-align: center;

      /* BC White */

      color: #FFFFFF;

      text-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
    }

  }

}

.topSectionSearch {
  position: relative;
  @media screen and (min-width: 1024px) {
    justify-content: center;
  }

  @media screen and (max-width: 1024px) {
    // margin-bottom: 70px;
    // top: 58px;

    margin-bottom: 5px;
  }

  margin-bottom: 20px;
  width: 100%;
  overflow: auto;
}

.contentSearch {
  min-height: 632px;
  @media screen and (min-width: 1024px) {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

.callToSignUpContainer {
  background: linear-gradient(rgba(0 47 74 / 50%), rgba(0 47 74 / 50%));

  @media screen and (max-width: 1024px) {
    // background: linear-gradient(rgba(0 47 74 / 50%), rgba(0 47 74 / 50%)), url('../public/images/deals-bg.webp') no-repeat;
    background-size: 300%;
    background-position: 75% 6%;
  }
}

.featuredContentWrapper {
  padding: 40px 0 130px;

  width: 100%;
  background-color: #001724;
  display: flex;
  flex-direction: column;

  .featuredContent {
    // width: 1020px;
    @media screen and (max-width: 1024px) {
      padding-bottom: 80px;
      height: auto
    }
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
      transform: scale(.9);
    }
   
  }
}


.content {
  min-height: 50vh;
  @media screen and (min-width: 1024px) {
    margin-top: -140px;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 17px;

  }
}

.tabs {
  background-color: red;
}

.moreButton {
  color: #0a0a0a;
  border: 1px solid #0a0a0a;
  line-height: 3.125rem;
  padding: 0 20px;
  font-weight: 600;
  text-transform: uppercase;

  &:hover {
    text-decoration: none !important;
  }
}

.review {

  cursor: pointer;
  background-color: #ffffff;
}

.recommendationsList {
  a {
    margin-bottom: 1.25rem;
  }
}


.regionContentWrapper {
  padding-bottom: 1rem;
  position: relative;
  background: linear-gradient(rgba(0 47 74 / 50%), rgba(0 47 74 / 50%));
  color: #fff;

  @media screen and (max-width: 1024px) {
    // background: linear-gradient(rgba(0 47 74 / 50%), rgba(0 47 74 / 50%)), url('../public/images/deals-bg.webp') no-repeat;
    background-size: 300%;
    background-position: 75% 6%;
    padding: 0 10px 20px;
  }

  .regionContent {
    min-height: 210px;

    @media screen and (max-width: 1024px) {
      min-height: 235px;
    }
  }

  .regionGlobeImage {
    background: url('../public/images/deals-bg.webp') no-repeat;
    background-size: 270%;
    background-position-x: 70%;

    @media screen and (max-width: 1024px) {
      background-size: 170%;
      background-position: 70% 10%;
      min-height: 100px;
      
    }
  }

  .regionTitle {
    font-size: 1.5rem;
    font-weight: normal;
    color: #D4A976;

    @media screen and (max-width: 1024px) {
      font-size: 1rem;
    }
  }

  .regionItem {
    border-bottom: 1px solid #fff;
    padding: 3px 0;
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 1rem;

    &:hover {
      font-weight: bold;
      cursor: pointer;
    }

    @media screen and (max-width: 1024px) {
      font-size: 0.8rem;
    }
  }
  .regionName {
    width: 33%;
  }
  .regionImage {
    width: 34%;


    img {
      width: 28px;
    }

    
  }
  .regionPrice {
    width: 33%;
    text-align: right;

  }

  .heading {
    color: #D4A976;
    font-size: 3.5rem;
    margin-top: 2rem;
    font-family: '"Libre Franklin", sans-serif';

    @media screen and (max-width: 1024px) {
      font-size: 3rem;
    }
  }

  .showDealsButton {
    font-size: 1rem;
    line-height: 41px;
    letter-spacing: 0.0625rem;
    font-weight: 500;
    padding: 0 20px;
    width: 225px;
    background: #D4A976;
    border-radius: 10px;
    display: block;
    text-align: center;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-20px);}
  60% {transform: translateY(-10px);}
}

.trustSection {
  text-align: center;
  margin-bottom: 1rem;

  @media screen and (max-width: 1024px) {
    margin-bottom: 0;
  }
}

.trustSectionContainer {
  background-color: #fff;
  position: relative;
  width: 100%;
  padding-top: 0.3rem;

  .carouselContainer {
    padding: 0 1rem 0.5rem;
  }
  
  .carouselItem {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .trustItem {
    padding: 0 0.5rem;

    .trustUsers {
      display: flex;
      justify-content: center;

      img {
        width: auto;
        height: 35px;
      }
    }

    .icon {
      img {
        width: auto;
        height: 35px;
      }
    }
  }
  
  .logos {
    display: flex;
    justify-content: center;
    min-height: 30px;
  
    img {
      max-width: 50px;
      height: auto;
    }
  }
  
  
  .text {
    margin-top: 0.5rem;
    text-align: center;
  
    h3 {
      font-size: 1.1rem;
      margin-bottom: 0.1rem;
      line-height: 1.4rem;
      font-weight: 600;
      white-space: nowrap;
    }
  
    p {
      font-size: 0.813rem;
      color: #666;
  
      @media screen and (max-width: 1024px) {
        text-align: center;
      }
    }
  }
  
  
  .trustpilot {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 30px;
  
    img {
      max-width: 160px;
      height: 30px;
    }
  }
  
  .icon {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  
    svg {
      width: 30px;
      height: 30px;
      color: #3C6562;
    }
  }
 
}

@media (min-width: 769px) {
  .trustSection {
    display: flex;
    justify-content: space-around;
  }

  // .carouselContainer {
  //   display: none;
  // }

  .trustItem {
    flex: 1;
    text-align: left;
    margin: 0 0.2rem;
  }
}

