.customMarker {
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding: 5px;
  position: relative;
  top: -10%;
  // left: -50%;
  overflow: visible;
  font-size: 0.9rem;
  min-width: max-content;
  // pointer-events: auto;

  &:hover {
    background-color: #b38e56;

    &::after {
      border-top-color: #b38e56;
    }
  }
  .priceWrapper{
    min-width: max-content;
  }

  .labelText {
    // max-width: 100px;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // display: inline-flex;
  }

  @media screen and (max-width: 1024px) {
    min-width: none;

    .priceWrapper{
      min-width: none;
      line-height: 1;

      span {
        font-size: 13px;
      }
    }

    span.labelText {
      font-size: 10px;
    }
  }
}

.customMarker::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.customMarker.currentMarker {
  background: #b38e56;
  color: #fff;
  border: 1px solid #b38e56;

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: #b38e56 transparent transparent transparent;
  }

  &::after {
    border-color: #b38e56 transparent transparent transparent;
  }

  &::after {
    border-color: transparent;
  }

  
}

.customMarker.activeMarker {
  background: #fff;
  color: #000;
  border: 1px solid black;

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  &::after {
    border-color: #fff transparent transparent transparent;
  }
}

.pointMarker {
  text-align: center;
  color: #fff;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 2;

  .pointMarkerIcon {
    width: 15px;
    height: 15px;
    border: 1px solid #FAF8F4;
    border-radius: 50%;
    background-color: #385179;
    cursor: pointer;
  }
}

.popup {
  display: none;

  .popupContent {
    width: calc(100% - 110px);
  }

  &.popupActive {
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    position: absolute;
    right: -255px;
    width: 250px;
    top: 0;
    z-index: 1000;
    text-align: center;
    background: #fff;
    border-radius: 6px;
    padding: 5px;
  }

  .title, .price {
    font-weight: bold;
    color: #000
  }

  .image {
    width: 110px;
    height: 70px;
    margin: auto 0;
  }

  .rating {
    width: 100px;
    margin: auto;
  }

  a {
    display: block;
    margin: auto;
    background: #000;
    color: #fff;
    width: 50%;
    margin-top: 5px;
    padding: 5px 0;
    text-align: center;
  }
}

.mapContainer {
  width: 100%;
  height: 100%;
  min-height: 810px;

  @media screen and (max-width: 1024px) {
    min-height: 460px;   
  }
}
