
.vintageBorder {
  width: 100%;
  position: absolute;
  top: -1px;
  @media screen and (min-width: 1024px) {
    stroke-width: .2;
  }
}

.footer {
  padding: 90px 0;
}

.snLogo {
  height: 32px;
  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }
}

.footerContent {
  color: rgba(250, 248, 244, 1);
  fill: rgba(250, 248, 244, 1);
}

.link {
  height: 31px;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  flex-direction: column;
  justify-content: flex-end;
  color: rgba(212, 169, 118, 1);
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.caption {
  font-size: 13px;
  width: 300px;
}

.captionConnect {
  font-size: 13px;
}

.closeDialog {
  position: absolute !important;
  top: 8px;
  right: 8px;
  z-index: 1;
  border-radius: 50%;
  background-color: #0a0a0a;
  color: rgba(250, 248, 244, 1);

  &:hover {
    background-color: #0a0a0a;
  }
}
