.snGoogleButton {
  svg {
    width: 16px;
    margin-top: -8px;
    margin-left: -1px;
  }

}

.dialogContainer {
  // background-color: #F7F7F7;
  background: linear-gradient(rgba(0 47 74 / 60%), rgba(0 47 74 / 60%)), url('../../public/images/deals-bg.webp') no-repeat;
  background-size: 300%;
  background-position: 75% 6%;
  border-radius: 0;
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #fff;
  @media screen and (max-width: 1024px) {
    height: 100vh;
    background-size: 390%;
    padding-bottom: 0;
    overflow-y: scroll;
    border: none;
  }

  & > * {
    color: #fff;
  }

  a {
    font-weight: bold;
    color: #fff;
    text-decoration: underline;
  }

  &.noBackground {
    background: none !important;
    border: none !important;
  }
}

.authContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1024px) {
    max-height: 100%;
  }
}

.subHeader {
  color: #D4A976;
  display: block;
  font-size: 1.3rem;
  margin-top: 15px;
  // font-weight: bold;
}

.header {
  display: block;
  font-size: 1.875rem;
  letter-spacing: 0.06875rem;
  font-weight: 400;
  padding: 0 50px;

  @media screen and (max-width: 1024px) {
    font-size: 1.6rem;
    padding: 0;
    letter-spacing: normal;
  }
}

.fieldInfo {
  margin-bottom: 1.875rem;
  font-size: 1rem;
  line-height: 1.875rem;
  text-align: center;
  font-weight: 400;
}

.haveAccount {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
  display: block;
  font-family: "PPFragment", serif;
  font-style: italic;
  font-size: 1.3rem;
  line-height: 1em;
  letter-spacing: 0;

  span {
    text-decoration: underline;
    cursor: pointer;
  }

  hr {
    border-color: #D4A976;
  }
}

.haveAccountInError {
  text-align: center;
  display: block;
  font-family: "PPFragment", serif;
  font-style: italic;
  font-size: 1rem;
  line-height: 1em;
  letter-spacing: 0;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
}


.loginButton {
  font-size: 1rem;
  line-height: 41px;
  letter-spacing: 0.0625rem;
  font-weight: 500;
  padding: 7.5px 20px;
  width: 225px;
  background-color: #D4A976;
  border-radius: 10px;
}

.forgotPassword {
  cursor: pointer;
  margin-top: 1.25rem;
  font-size: 0.875rem;
  line-height: 1em;
  display: block;
  margin-bottom: 2em;
  color: #fff;
  text-decoration: underline;
}

.closeDialog {
  position: absolute !important;
  top: 8px;
  right: 8px;
  z-index: 1;
  border-radius: 50%;
  background-color: #D4A976 !important;
  color: white !important;

  &:hover {
    background-color: #86694B !important;
  }
}


.checkbox {
  label {
    position: relative;
  }

  &[type=checkbox], &[type=radio] {
    opacity: 0;
    position: absolute;
    line-height: 30px;
    margin-top: 6px;

    &:checked + label:before {
      background: #202020;
    }

    & + label:after,
    & + label:before {
      position: absolute;
      content: "";
      padding: 0;
      border-radius: 50%;
      box-sizing: content-box !important;
      text-align: center;
      left: calc(50% - 70px);
      top: 0;
      color: white;
      background: white;
    }

    & + label:after {
      border: 0.0625rem solid #9b9b9b;
      //margin-right: 0.5rem;
      //margin-top: 0.1rem;
      width: 1.25rem;
      height: 1.25rem;
    }

    & + label:before {
      z-index: 100;
      position: absolute;
      top: 0.25rem;
      left: calc(50% - 66px);
      width: 0.875rem;
      height: 0.875rem;
    }

    & + label span {
      position: relative;
      left: 2rem;
    }
  }

  &[type=checkbox]:not(.round) {
    & + label:after,
    & + label:before {
      border-radius: 15%;
    }
  }
}

.checkboxLabel {
  font-size: 1.25rem;
  letter-spacing: 0;
  text-align: center;
  font-weight: normal;
  line-height: 100%;
  padding-left: 24px;
  font-variant: small-caps;
  color: #fff;
  float: left;
  width: 100%;
}

.input {
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 1.7rem 0.5rem;
  background-color: #ffffff;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  color: #202020;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  border: 1px solid rgba(32, 32, 32, 0.2);
  border-radius: 10px;
}

.label {
  font-size: 0.938rem;
  letter-spacing: 1px;
  line-height: 30px;
  color: #fff;
  width: 100%;
  float: left;
}

.passwordReset .label {
  color: #202020;
  text-align: left;
}

.error {
  margin-bottom: 1rem;
  padding: 2rem;
  position: relative;
  border: 1px solid #D4A976;
  color: #D4A976;
  font-style: italic;
  font-size: 1.375rem;
}

.errorLink {
  cursor: pointer;
  color: #202020;
}

@keyframes pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.loader {
  margin: auto;
  width: 60px;
  height: 60px;
  background-color: #c19237;
  border-radius: 100%;
  animation: pulseScaleOut 1s infinite ease-in-out;
}

.inputError {
  background-color: #ec58401A;
  border: solid 1px #D4A976;

}

.inputErrorMessage {
  margin-top: -16px;
  display: block;
  color: #D4A976;
  width: 100%;
  font-size: 0.813rem;
  line-height: 1;
  font-weight: bold;
}

.snLogo {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50% !important;
  padding: 4px !important;
  border: 1px solid #dadce0 !important;
  max-width: unset !important;
  background-color: white;
  margin-right: 0.625rem;
  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }
}


.facebookLogin {
  display: flex;
  width: 203px;
  height: 38px;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 4px;
  border: 1px solid #dadce0;
  font-weight: 600;
  padding: 4px 10px;
  color: #3c4043;
  margin-bottom: 8px;
  font-family: "Google Sans", Arial, sans-serif;
  font-size: .825rem;
  position: relative;
  border-radius: 4px;

  svg {
    left: 8px;
    position: absolute;
    width: 24px !important;
    height: 24px !important;
    display: block;
  }
}

.googleLogin {
  display: flex;
  width: 225px;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: #dc4935;
  padding: 4px 10px;
  color: white;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: .825rem;
  border-radius: 10px;

  .snLoginWrap {
    width: 40px;
    margin-left: -14px;
    margin-right: 2px;
  }

  .snLogin {
    font-size: 2rem;
    line-height: 1em;
    letter-spacing: 0;
    width: 24px;
    height: 24px;
  }
}

.authDarkTheme {
  color: #fff;

  .label,
  .checkboxLabel,
  .forgotPassword {
    color: #fff;
  }

  .loginButton {
    background-color: #D4A976 !important;
  }

  a {
    font-weight: bold;
    color: #fff;
    text-decoration: underline;
  }
}
