.layout {
  width: 100%;
}

.cookieDialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 4.25rem;
  text-align: center;
  @media screen and (max-width: 1024px) {
    line-height: 1.5;
    padding: 2rem 2rem;
  }

  a {
    color: #202020;
    text-decoration: underline;
  }

  .cookieDialogHeading {
    font-size: 2rem;
  }
}

.signUpDialogContainer {
  position: fixed;
  bottom: 0px;
  width: 370px;
  height: 50px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../public/images/deals-bg.webp') no-repeat;;
  padding: 10px;
  z-index: 1000;
  background-size: 220%;
  background-position: 85% 5%;
  @media screen and (max-width: 700px) {
    bottom: 0px;
    width: 100Vw;
  }

  .closeSignUpDialog {
    color: #FFF !important;
    position: absolute !important;
    top: 7px;
    right: 8px;
    z-index: 1;
  }

  .registerOverlay {
    .signUpButton {
      color: #FFF !important;
      margin-top: 5px;
      padding-left: 6px;
      font-size: 1rem;
      display: block;
      text-decoration: unset;
    }

    @media screen and (max-width: 700px) {
      display: flex;
      .closeSignUpDialog {
        order: 1;
        left: 8px;
        right: auto;
      }

      .signUpButton {
        order: 2;
        padding-left: 40px;
      }
    }
  }
}

// .loginDialog {
//   background: url('../public/images/deals-bg.webp') no-repeat;
//   background-size: 200%;
//   background-position-x: right;
// }


.closeDialog {
  position: absolute !important;
  top: 8px;
  right: 8px;
  z-index: 1;
  border-radius: 50%;
  background-color: #D4A976 !important;
  color: white !important;

  &:hover {
    background-color: #86694B !important;
  }
}

.dealsDialogContainer {
  background: url('../public/images/deals-bg.webp') no-repeat;
  background-size: 200%;
  background-position-x: right;
  border: 1px solid #fff;

  @media screen and (max-width: 1024px) {
    background-size: cover;
    min-height: auto;
    background-position-x: 15%;
  }
}
.dealsDialog {
  margin: auto;
  padding-bottom: 0;
  margin-bottom: 1rem;

  .heading {
    font-size: 3rem;
    line-height: 3rem;
    margin-top: 1rem;
    font-family: '"Libre Franklin", sans-serif';

    @media screen and (max-width: 1024px) {
      font-size: 2rem;
      line-height: 2rem;
    }

    & .higlighted {
      color: #D4A976;
    }
  }

  p {
    color: #fff;
  }
}

.proceedButton {
  font-size: 0.8250rem;
  line-height: 41px;
  letter-spacing: 0.0625rem;
  font-weight: 500;
  padding: 0 20px;
  width: 225px;
  background: #D4A976;
  border-radius: 10px;
}

.scrollToTopButton {
  position: fixed !important;
  bottom: 20px;
  left: 30px;
  width: 40px;
  height: 40px;
  z-index: 99;
  border: none !important;
  outline: none !important;
  background-color: #3C6562 !important;
  color: white !important;
  border-radius: 50% !important;;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

  &:hover {
    background-color: #00454A;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3), 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  }

  @media screen and (max-width: 1024px) {
    bottom: 5px;
    left: 5px;
    width: 35px;
    height: 35px;
  }
}
