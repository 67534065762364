.offerData {
    width: 80%;
    padding-left: 0.5rem;
    .offerOverline {
      font-size: 11px;
      color: #9b9b9b;
      margin-bottom: 0;
      width: 100%;
      padding: 0;
      line-height: 1.5;
    }
  
    .airlineLogo {
      max-height: 35px;
    }
  
    .offerPrice {
      font-size: 1.1rem;
      font-weight: 500;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      i {
        font-style: normal;
        color: #9b9b9b;
        font-size: 0.813rem;
      }
    }
  
    .regularPrice {
      color: #bb9269;
      font-size: 0.813rem;
      font-weight: 600;
    }
  
    .offerLink {
      text-decoration: none;
      color: #fff;
      background-color: #3c6562;
      font-size: 1rem;
      padding: 10px 20px;
      width: 225px;
      border-radius: 10px;
      display: block;
      text-align: center;
    }
  
    h3 {
      text-align: left;
      font-weight: 400;
    }
  }
  
  .closeDialog {
    z-index: 1;
    border-radius: 50%;
    background-color: #d4a976 !important;
    color: white !important;
    margin-bottom: 0.5rem;
    width: 40px;
    height: 40px;
  
    &:hover {
      background-color: #86694b !important;
    }
  }
  
  .closeShareDialog {
    position: absolute !important;
    top: 5px;
    right: 5px;
    z-index: 200;
    width: 50px;
    height: 50px;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .offerDetailsContainer {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.02);
  
    @media screen and (max-width: 1024px) {
      padding: 10px;
    }
  }
  
  .shareIcon {
    position: absolute !important;
    bottom: 4px;
    right: 4px;
    z-index: 10;
    height: 40px;
    width: 40px;
  
    @media screen and (max-width: 1024px) {
      top: 4px;
      bottom: auto;
      background-color: #d4a976 !important;
    }
  }