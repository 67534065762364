.consentBanner {
    position: fixed;
    bottom: 0;
    max-height: 200px;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    z-index: 9999;

    @media screen and (max-width: 1024px) {
        
    }
}

.consentDialog {
    h6 {
        text-align: left
    }

    @media screen and (max-width: 1024px) {
        p {
            font-size: 0.75rem;
            line-height: normal;
        }
        h6 {
            font-size: 0.83rem;
            text-align: left;
        }
    }
}